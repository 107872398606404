@import 'scss/_variables';
@import 'scss/_mixins';

.toast_wrapper {
    width: 100%;
    height: auto;
    position: relative;
}

.toasts_container {
    position: fixed;
    right: 24px;
    bottom: 12px;
    z-index: 5010;
}
