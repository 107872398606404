@import 'scss/_variables';
@import 'scss/_mixins';

.primary_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    @include set_font(16px, 18px, 500, false, $primary_text_color);
    font-family: 'Maax';
    border: none;
    background: $primary_button_color;
    outline: none;
    cursor: pointer;
    border-radius: 4px;

    transition: background 0.3s ease-out;

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }

    > svg {
        margin-right: 16px;
    }
}
