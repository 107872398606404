.content_main {
    max-width: 480px;
    margin: 0 auto;
}

.content {
    background-color: #4a2aab;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    border-radius: 6px;
}

.content_text {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.2px;
    width: 190px;
    text-align: center;
}

.vertical_divider {
    height: 50px;
    width: 2px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        width: 1px;
        height: 100%;
        opacity: 0.31;
        background-color: #fff;
    }

    &::after {
        content: '';
        display: block;
        right: 0;
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #3612a3;
    }
}

.button {
    background-color: #7446ff;
}

.content_divider {
    margin-top: 14px;
    margin-bottom: 14px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
}

.content_light {
    background-color: #623fca;
}
