@import 'scss/_variables';
@import 'scss/_mixins';

:root {
    --delay: 5s;
}

.toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 120px;
    width: 475px;
    border-radius: 4px;
    background-color: #232323;
    box-shadow: 4px 14px 10px 0 rgba(#000, 0.4);
    margin-bottom: 12px;
    padding: 24px 24px 24px 32px;
    visibility: hidden;
    z-index: 5010;
    position: relative;

    svg {
        @include square(32px);
    }
}

.show {
    visibility: visible;
    animation: fadein 0.7s, fadeout 0.7s var(--delay);
}

@keyframes fadein {
    0% {
        opacity: 0;
        bottom: -500px;
    }

    70% {
        bottom: 30px;
    }

    100% {
        opacity: 1;
        bottom: 0;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
        right: 0;
    }

    30% {
        right: 20px;
    }

    100% {
        opacity: 0;
        right: -1000px;
    }
}

.right {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.left {
    display: flex;
    align-items: center;
}

.text {
    word-wrap: break-word;
}

.title {
    @include set_font(18px, 23px, 500);
    width: 100%;
}

.subtitle {
    @include set_font(14px, 18px, 400);
    width: 100%;
}

.content {
    margin-right: 24px;
}

.accept_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 40px;
    padding: 0 16px;
    @include set_font(14px, 22px, 500);
    border: none;
    background: #7446ff;
    color: $primary_text_color;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.decline_button {
    @extend .accept_button;
    background: transparent;
}
