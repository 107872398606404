// Brands
$button_color: #613ee8;
$primary_button_color: #614BEF;
$secondary_bck: #4c27da;
$link: #614BEF;
$link_alt: #727272;
$error: #E60505;
$input_text: #fff;
$input_bg: #4c4c4c;

// Body
$body_bg: #141414;

// Typography
$primary_text_color: #fff;
$base_font_size: 16px;
$base_font_weight: 400;

// Global
$wideBreakPoint: 770;

// Logo position
$logoPaddingMobile: 24;
$logoPaddingLeftWide: 56;

// ----------------- New Design ----------------- //
// Brands -----------------
$link: #53a8e2;
$button: #53a8e2;
$tag: #604fc9;
$notification: #53a8e2;
$card_bg: #181818;
$win: #ff6262;
$lose: #32b17b;
// ------------------------

// Typography ----------------
$primary_text_color: #fff;
//  --------------------------

// Breakpoints ------------
$breakpoints: (
    'mobile_small': (
        max-width: 415px,
    ),
    'mobile': (
        max-width: 767px,
    ),
    'tablet': (
        max-width: 1023px,
    ),
    'laptop': (
        max-width: 1279px,
    ),
    'desktop': (
        max-width: 1439px,
    ),
    'desktop_large': (
        // max-width: 1599px,
        max-width: 1920px,
    ),
) !default;
//  ----------------------
