.content_wrapper {
    position: relative;
    max-width: 670px;
    background: url('../../assets/images/subscription_prompts/puzzles.png')
        no-repeat right / cover;

    padding: 50px 70px 40px;
}

.content_closeModal {
    position: absolute;
    right: 24px;
    top: 24px;
}

.title_content {
    margin-bottom: 24px;
}

.title {
    color: #ffffff;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 50px;
    text-align: center;
}

.subtitle {
    margin-bottom: 50px;
    font-size: 18px;
}
