.badgeAndIcon {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;

    .playerInfoicon {
        z-index: 10;
    }

    .playerInfoBadge {
        position: relative;
        right: 8px;
    }

    .playerInfoBadgeMirror {
        position: relative;
        left: 10px;
    }
}
