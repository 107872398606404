@import 'scss/_variables';
@import 'scss/_mixins';

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Maax', sans-serif;
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Maax';
    font-display: auto;
    src: url(./assets/fonts/Maax.otf);
    font-weight: 400;
    font-feature-settings: 'ss03' 1;
}

@font-face {
    font-family: 'Maax';
    font-display: auto;
    src: url(./assets/fonts/Maax-Medium.otf);
    font-weight: 500;
    font-feature-settings: 'ss03' 1;
}

@font-face {
    font-family: 'Maax';
    font-display: auto;
    src: url(./assets/fonts/Maax-Bold.otf);
    font-weight: 600;
    font-feature-settings: 'ss03' 1;
}

@font-face {
    font-family: 'Maax';
    font-display: auto;
    src: url(./assets/fonts/Maax-Black.otf);
    font-weight: 700;
    font-feature-settings: 'ss03' 1;
}

body {
    margin: 0;
    padding: 0;
    color: $primary_text_color;
    background-color: $body-bg;
    font-size: $base_font_size;
    font-weight: $base_font_weight;
    font-family: 'Maax', sans-serif;
    font-feature-settings: 'ss03' on;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
        display: none;
    }

    .react-confirm-alert-overlay {
        background: transparent;
    }
}

a {
    font-family: 'Maax', sans-serif;

    &:visited {
        color: #fff;
    }
}

p {
    font-family: 'Maax', sans-serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 29px;
}

h1 {
    font-family: 'Maax', sans-serif;
    font-weight: 500;
    font-size: 54px;
    line-height: 65px;
}

h2 {
    font-family: 'Maax', sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
}

h3 {
    font-family: 'Maax', sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
}

h4 {
    font-family: 'Maax', sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 29px;
}

button {
    font-family: 'Maax', sans-serif;
}

button::-moz-focus-inner {
    border: 0;
}

input::-moz-focus-inner {
    border: 0;
}

.spinner_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blur {
    /* Fallback to SVG filters */
    filter: url('#blur');
    filter: blur(8px);
}

.no_scroll {
    overflow: hidden;
}

.separator {
    height: 1px;
    border-radius: 2.5px;
    background-color: #151515;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5),
        0 1px 1px 0 rgba(255, 255, 255, 0.1);
}

input[type='number'] {
    text-align: center;

    &::-webkit-input-placeholder {
        text-align: center;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        text-align: center;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        text-align: center;
    }

    &:-ms-input-placeholder {
        text-align: center;
    }
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 18px;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #3f3e3e;
    border: solid 7px transparent;
    border-radius: 10px;
}
