.splash {
    width: 100vw;
    height: 100vh;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    background-image: url(~assets/icons/logo.svg);
    background-repeat: no-repeat;
    background-position: center;
}
