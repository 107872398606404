@import 'scss/_variables';
@import 'scss/_mixins';

.fallback_page {
    width: 100vw;
    height: 100vh;
    position: static;
    z-index: 440;
    background-image: url('../../assets/images/BG@2x.png');
    background-position: center;
    background-size: cover;
    @include flexBox(null, flex-start, center);
    justify-content: space-around;
    align-items: center;
    padding: 0 10%;
    flex-direction: column;

    h1 {
        font-family: Maax;
        font-style: normal;
        font-weight: 500;
        font-size: 70px;
        line-height: 87px;
        // width: 80%;

        @media only screen and (max-width: 870px) {
            font-size: 60px;
        }

        @media only screen and (max-width: 715px) {
            font-size: 50px;
        }

        @media only screen and (max-width: 580px) {
            font-size: 40px;
            line-height: 64px;
        }

        @media only screen and (max-width: 480px) {
            font-size: 35px;
        }
    }
}

.mobile {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/fallback_mobile.png');
    background-position: center;
    background-size: cover;
    @include flexBox(column, space-between, center);
    padding: 36px 22px 30%;

    .header {
        width: 100%;
        @include flexBox(null, center, center);

        svg {
            @include square(46px);
        }
    }

    .main_container {
        @include flexBox(column, center, center);

        h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 40px;
            text-align: center;
        }


    }
}

.buttons_large {
    @include flexBox(row, space-between, center);
    width: 100%;
    height: 44px;
    margin-top: 40px;
    transform: scale(2);

    .app_store {
        width: 152px;
        height: 44px;
        background: {
            image: url(~assets/icons/app_store_badge.png);
            position: center;
            size: contain;
            repeat: no-repeat;
        }
    }

    .google_play {
        @extend .app_store;
        background: {
            size: contain;
            image: url(~assets/images/google-store.png);
        }
        transform: scale(1.5);
    }
}

.buttons {
    @include flexBox(row, space-between, center);
    width: 100%;
    height: 44px;
    margin-top: 40px;

    .app_store {
        width: 152px;
        height: 44px;
        background: {
            image: url(~assets/icons/app_store_badge.png);
            position: center;
            size: contain;
            repeat: no-repeat;
        }
    }

    .google_play {
        @extend .app_store;
        background: {
            size: contain;
            image: url(~assets/images/google_play.png);
        }
        transform: scale(1.05);
    }
}

.four_o_four {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/BG@2x.png');
    background-position: center;
    background-size: cover;

    .logo {
        box-sizing: content-box;
        max-width: 1760px;
        height: 75px;
        padding: 32px 0;
        margin: 0 auto;

        @include respond_to('desktop_large') {
            max-width: 1584px;
            padding: 32px 45px;
        }

        @include respond_to('desktop') {
            max-width: 100%;
            padding: 32px 40px;
        }
    }

    .main {
        box-sizing: content-box;
        max-width: 1760px;
        padding: 32px 45px;
        margin: 0 auto;
        @include flexBox(column, center, flex-start);

        @include respond_to('desktop_large') {
            max-width: 1584px;
            padding: 5% 45px 32px;
        }

        @include respond_to('desktop') {
            max-width: 100%;
            padding: 32px 40px;
        }

        a {
            text-decoration: none;
            color: #fff;
        }

        h3 {
            width: 900px;
            font-weight: 500;
            font-size: 80px;
            line-height: 96px;
        }

        p {
            font-weight: 500;
            font-size: 22px;
            line-height: 84px;
        }

        .back_to_home {
            @include flexBox(null, center, center);
            width: 236px;
            height: 56px;
            background-color: #7446ff;
            border-radius: 4px;
            font-weight: 500;
            font-size: 18px;
        }
    }
}

.schedule_maintenance {
    @extend .four_o_four;
}
