@import 'scss/_variables';
@import 'scss/_mixins';

.product_payment_success {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 45px;

	h2 {
		margin-bottom: 24px;
	}
}
