@import 'scss/_variables';
@import 'scss/_mixins';

.layout {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 100vw;
    height: 100%;
}

.bg {
    @extend .layout;
    background-color: #000;
    background-image: linear-gradient(226.03deg, #4c3bba 0%, #0e3178 100%);
    box-shadow: 0 14px 10px 0 rgba(0, 0, 0, 0.2);
}

.main {
    width: 100%;
    min-height: 100vh;
    position: relative;

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
    }
}

.view_more_link {
    @include blue_flash_kill;
    background-color: #fff;
    width: 24px;
    height: 24px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-image: url('~assets/icons/arrows/arrow_scroll_top.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    padding: 24px;
    cursor: pointer;
    outline: none;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
    border-radius: 6px;

    &:focus {
        outline: none;
    }

    &:hover {
        animation: zoom_in cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s forwards;
    }

    @keyframes zoom_in {
        0% {
            transform: scale(1);
        }

        60% {
            transform: scale(0.85);
        }

        100% {
            transform: scale(1);
        }
    }
}

.show {
    z-index: 5;
    opacity: 1;
    @include basic_transition(opacity, 0.8);
}

.hide {
    z-index: -1;
    opacity: 0;
    @include basic_transition(opacity, 0.8);
}

.cookie_banner {
    width: calc(100vw - 48px);
    min-height: 96px;
    background-color: #7446ff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4.5px;
    @include push_auto;
    position: fixed;
    bottom: 24px;
    left: 24px;
    right: 24px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    animation: apear 0.3s ease-in;
    z-index: 500;

    @media screen and (min-width: 768px) {
        display: flex;
        padding: 20px 44px 20px 28px;
    }

    @keyframes apear {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    > div:last-of-type {
        height: 100%;
        display: flex;
        align-items: center;
    }

    h4 {
        @include set_font(18px, 20px, 500, -0.18px);
        margin-bottom: 10px;
    }

    p {
        @include set_font(16px, 18px, 400, -0.16px);

        a {
            color: #fff;

            &:visited {
                color: inherit;
            }
        }
    }
}

.show_banner {
    @media screen and (min-width: 768px) {
        display: flex;
    }
}

.hide_banner {
    display: none;
}

.btn {
    position: relative;
    text-align: center;
    width: 135px;
    height: 56px;
    padding: 0 16px;
    @include set_font(14px, normal, 400, -0.16px);
    border: 1px solid #fff;
    background: transparent;
    color: $primary_text_color;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 16px;
    margin-top: 20px;

    @media screen and (min-width: 768px) {
        @include set_font(16px, 32px, 400, -0.16px);
        margin-top: 0;
    }

    &:disabled {
        cursor: default;
        opacity: 0.4;
    }

    &:last-of-type {
        border-width: 2px;
        font-weight: 500;
    }
}
